import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { tablet, desktop, mobile } from 'utils/media';
import theme from 'utils/theme';
import { ArrowLayout, CarouselItemProps } from './types';

const scrollbarHeight = 3;
const scrollbarPadding = 20;

const scrollbarStyles = (inline: boolean) => ({
  '::-webkit-scrollbar': {
    height: scrollbarHeight,
    backgroundColor: 'rgba(255,255,255,0)',
  },
  '::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb': {
    border: `${scrollbarPadding}px solid rgba(255,255,255,0)`,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    backgroundClip: 'padding-box',
    ...(inline
      ? { [tablet]: { borderLeftWidth: 0, borderRightWidth: 0 } }
      : { [tablet]: { borderLeftWidth: 40, borderRightWidth: 40 } }),
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: theme.colors.scrollbarBackground,
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colors.darkGreen,
  },
});

interface CarouselRowInnerProps {
  inline?: boolean;
  snap?: boolean;
}

export const CarouselRowInner = styled.div<CarouselRowInnerProps>(
  ({ inline = false, snap = true }) => ({
    display: 'flex',
    flex: 1,
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '0 0 20px',
    overscrollBehaviorX: 'contain',
    scrollSnapType: snap ? 'x mandatory' : undefined,

    ...scrollbarStyles(inline),
  }),
);

export const CarouselRowContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

interface ArrowButtonProps {
  arrowLayout: ArrowLayout;
}

export const ArrowButton = styled.button<ArrowButtonProps>`
  ${mobile} {
    display: none;
  }

  position: ${(props) =>
    props.arrowLayout === 'overlay' ? 'absolute' : 'static'};
  top: 50%;
  appearance: none;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  z-index: ${theme.zIndexes.mid};
  cursor: pointer;
  color: ${theme.colors.darkGreen};
  width: 37px;
  height: 37px;
  line-height: 0;
  font-size: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ disabled, arrowLayout }) =>
    disabled
      ? arrowLayout === 'top'
        ? `opacity: 0.5;`
        : `visibility: hidden;`
      : ``}

  ${({ arrowLayout }) =>
    arrowLayout === 'overlay'
      ? `transform: translateY(-50%);`
      : arrowLayout !== 'top'
      ? `margin-top: 46px;`
      : ''}

  svg {
    width: 29px;
    height: 29px;
  }

  &:first-child {
    left: ${(props) => (props.arrowLayout === 'overlay' ? '20px' : '0')};

    svg {
      transform: rotate(90deg);
    }
  }

  &:last-child {
    right: ${(props) => (props.arrowLayout === 'overlay' ? '20px' : '0')};

    svg {
      transform: rotate(-90deg);
    }
  }

  &:hover {
    ${({ arrowLayout }) =>
      arrowLayout !== 'top' ? `background: ${theme.colors.white};` : ''}
  }

  &:focus-visible {
    background: ${theme.colors.white};
    outline: none;
    box-shadow: 0 0 0 2px ${theme.colors.darkGreen};
  }
`;

const sizeStyles: { [key: string]: FlattenSimpleInterpolation } = {
  small: css`
    min-width: 132px;
    max-width: 132px;
  `,
  normal: css`
    min-width: 134px;
  `,
  large: css`
    min-width: 150px;
    width: 150px;
    flex-grow: 0;

    ${tablet} {
      min-width: 290px;
      width: 290px;
    }
  `,
  xlarge: css`
    width: 240px;
    min-width: 240px;

    ${tablet} {
      width: 290px;
      min-width: 290px;
    }
  `,
  xxlarge: css`
    width: 240px;
    min-width: 240px;

    ${tablet} {
      width: 400px;
      min-width: 400px;
    }
  `,
  full: css`
    box-sizing: border-box;
    min-width: 100%;
    width: 100%;
    flex-shrink: 0;
    padding: 0 20px;

    ${tablet} {
      padding: 0;
    }
  `,
};

export const CarouselItem = styled.div<CarouselItemProps>`
  box-sizing: content-box;
  flex-shrink: 0;
  padding: 0 10px 0 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  scroll-snap-align: center;

  ${({ inline }) =>
    inline
      ? css`
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
          }
          ${tablet} {
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        `
      : css`
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
          }
          ${tablet} {
            &:first-child {
              padding-left: 40px;
            }
            &:last-child {
              padding-right: 40px;
            }
          }
        `}

  ${({ size = 'normal' }) => sizeStyles[size]}
`;

export const ThumbnailsGrid = styled.div`
  margin-top: 30px;
  grid-auto-flow: row dense;
  column-gap: 15px;
  row-gap: 15px;
  display: flex;
  justify-content: center;

  ${tablet} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  ${desktop} {
    grid-template-columns: repeat(7, 1fr);

    & button:nth-child(n + 8) {
      display: none;
    }
  }
`;

export const ThumbnailLink = styled.button`
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const ThumbnailContent = styled.div`
  ${mobile} {
    display: none;
  }
`;

interface ThumbnailDotProps {
  active: boolean;
}

export const ThumbnailDot = styled.div<ThumbnailDotProps>`
  display: none;

  ${mobile} {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${(props) =>
      props.active ? theme.colors.darkGreen : theme.colors.scrollbarBackground};
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    border-radius: 100%;
  }
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin: 0 0 10px;
`;
